import * as React from "react";
import { SEO } from "../components/seo";
import { Layout } from "../components/layout";
import "./reel.css";

const Reel = () => {
  return (
    <Layout>
      <div className="reel-wrapper">
        <iframe
          className={"reel-iframe"}
          title={"Andrea Lopez acting reel"}
          src={"https://www.youtube.com/embed/2MRW7nb49Ps"}
          frameBorder={"0"}
          allow={
            "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          }
          allowFullScreen
        />
      </div>
    </Layout>
  );
};

export default Reel;

export const Head = () => <SEO title={"Reel"} />;
